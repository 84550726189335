import React from "react"
// import { Link } from "gatsby"
import { Layout } from "../components/layout"
import Seo from "../components/seo"
import { Controller, Scene } from 'react-scrollmagic'
import { BrowserView } from 'react-device-detect';

import { MagLinks } from "../components/MagLinks"

const aboutPage = () => {
    return (
        <Layout>
            <Seo
                title="About"
                description="DESIGN STUDIO KのAboutページです。理念やサービス内容、メンバー、所在地を掲載しています。"
            />
            <div className="eyecatch">
                <div className="eyecatch__inner">
                    <h1>
                        <span>About</span>
                    </h1>
                </div>
                <BrowserView>
                    <Controller>
                        <Scene
                            classToggle={[".eyecatch__under", "enter"]}
                            triggerHook="onEnter"
                            triggerElement=".enterContents"
                        // offset={300}
                        >
                            <div className="eyecatch__under">
                                <div className={`mv__underRight`} />
                            </div>
                        </Scene>
                    </Controller>
                </BrowserView>
            </div>
            <div className="about__container enterContents">
                <section className="about__containerSec">
                    <div className="about__containerSecBlock">
                        <div className="about__containerSecBlockTtl">
                            <h2>Vision</h2>
                        </div>
                        <div className="about__containerSecBlockTxt">
                            <div className="about__containerSecBlockTxtPhilo">
                                <div className="about__containerSecBlockTxtPhiloTtl">
                                    <h3>実直に<span>、<br className="br__sp" /></span>かっこよくあれ</h3>
                                    <span>Be honest, Be cool</span>
                                </div>
                                <div className="about__containerSecBlockTxtPhiloState">
                                    <div className="about__containerSecBlockTxtPhiloStateJp">
                                        <p>
                                            かっこよく思う、思われるというのは、<br />
                                            ビジュアルだけを指しているわけではありません。
                                        </p>
                                        <p>
                                            ひたむきに物事に向き合ったり、<br />
                                            誰かのために手を貸したり、<br />
                                            誰もができないだろうと思っていたことを成し遂げたり。
                                        </p>
                                        <p>
                                            一人ひとりがそれぞれの”かっこいい”を追求し、<br className="br__pc" />そのために地道に努力する。
                                        </p>
                                        <p>
                                            そんなかっこいい姿勢を持ち続けていきます。
                                        </p>
                                    </div>
                                    <div className="about__containerSecBlockTxtPhiloStateEn">
                                        <p>
                                            Being considered cool is not just about visuals.
                                        </p>
                                        <p>
                                            It can also mean facing things with dedication, lending a helping hand to someone, or accomplishing something that no one thought was possible.
                                        </p>
                                        <p>
                                            Each one of us pursues our own "coolness" and makes steady efforts to achieve it.
                                        </p>
                                        <p>
                                            We will continue to maintain such a cool attitude.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about__containerSec">
                    <div className="about__containerSecBlock">
                        <div className="about__containerSecBlockTtl">
                            <h2>Mission</h2>
                        </div>
                        <div className="about__containerSecBlockTxt">
                            <div className="about__containerSecBlockTxtPhilo">
                                <div className="about__containerSecBlockTxtPhiloTtl">
                                    <h3>クライアントの<br className="br__sp" />よき伴走者に</h3>
                                    <span>To be a good companion for the client</span>
                                </div>
                                <div className="about__containerSecBlockTxtPhiloState">
                                    <div className="about__containerSecBlockTxtPhiloStateJp">
                                        <p>
                                            デザインをするとき、必ずそこには誰かが抱える問題があり、<br className="br__pc" />それを解決していくプロセスを一緒に考えます。
                                        </p>
                                        <p>
                                            クライアントがいて、社会があって、時代がある。
                                        </p>
                                        <p>
                                            常に変遷するテクノロジーとともに<br />
                                            クライアントが抱える課題に対して最適解を提示します。
                                        </p>
                                        <p>
                                            1ピクセルも妥協しないモノづくりの姿勢で、<br />
                                            最高のユーザー体験をデザインの力で実現します。
                                        </p>
                                    </div>
                                    <div className="about__containerSecBlockTxtPhiloStateEn">
                                        <p>
                                            When designing, there is always a problem that someone else is facing, and we work together in the process of solving that problem.
                                        </p>
                                        <p>
                                            There is the client, society, and the times.
                                        </p>
                                        <p>
                                            We provide the best solutions to our clients' problems along with the ever-changing technology.
                                        </p>
                                        <p>
                                            With an uncompromising approach to craftsmanship, we use the power of design to create the best user experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about__containerSec">
                    <div className="about__containerSecBlock">
                        <div className="about__containerSecBlockTtl">
                            <h2>Service</h2>
                        </div>
                        <div className="about__containerSecBlockTxt">
                            <div className="about__containerSecBlockTxtService">
                                <div className="about__containerSecBlockTxtServiceBox">
                                    <h3>Direction</h3>
                                    <p>情報設計・プランニング・進行管理</p>
                                </div>
                                <div className="about__containerSecBlockTxtServiceBox">
                                    <h3>Web Design</h3>
                                    <p>Webサイト・アプリのデザイン</p>
                                </div>
                                <div className="about__containerSecBlockTxtServiceBox">
                                    <h3>Web Development</h3>
                                    <p>Webサイト・アプリの開発</p>
                                </div>
                                <div className="about__containerSecBlockTxtServiceBox">
                                    <h3>Maintenance Management</h3>
                                    <p>保守管理</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about__containerSec">
                    <div className="about__containerSecBlock">
                        <div className="about__containerSecBlockTtl">
                            <h2>Team</h2>
                        </div>
                        <div className="about__containerSecBlockTxt">
                            <div className="about__containerSecBlockTxtTeam">
                                <div className="about__containerSecBlockTxtTeamBox">
                                    <div className="about__containerSecBlockTxtTeamBoxName">
                                        <div className="about__containerSecBlockTxtTeamBoxNameKanji">
                                            <h3>北村 洸</h3>
                                            <p>代表 / デザイナー / フロントエンドエンジニア</p>
                                        </div>
                                        <div className="about__containerSecBlockTxtTeamBoxNameFurigana">
                                            <p>Koh Kitamura</p>
                                            <span>Founder / Designer / Front End Engineer</span>
                                        </div>
                                    </div>
                                    <ul className="about__containerSecBlockTxtTeamBoxSns">
                                        <li>
                                            <MagLinks path="https://www.facebook.com/KohKitamura1990/" outer>
                                                <p>Facebook</p>
                                            </MagLinks>
                                        </li>
                                        <li>
                                            <MagLinks path="https://www.instagram.com/koh_1990/" outer>
                                                <p>Instagram</p>
                                            </MagLinks>
                                        </li>
                                        <li>
                                            <MagLinks path="https://twitter.com/KOH_PLUS" outer>
                                                <p>X</p>
                                            </MagLinks>
                                        </li>
                                    </ul>
                                    <div className="about__containerSecBlockTxtTeamBoxTxt">
                                        <p>
                                            1990年宮城県石巻市生まれ。<br />
                                            山形県立産業技術短期大学校非常勤講師。
                                        </p>
                                        <p>東北芸術工科大学プロダクトデザイン学科中退。山形市のデザイン事務所を経て、印刷会社に勤務。中小のコーポレートサイトや官公庁関連のWEBサイト / メディアサイト、小売業のECサイトなど多岐に渡るサイト制作を行い、2019年に独立。2021年に法人化。</p>
                                        <p>好きなスポーツはサッカー。好きな音楽はザ・ルーツ。好きな食べ物はトマト系全般。</p>
                                    </div>
                                    <div className="about__containerSecBlockTxtTeamBoxEn">
                                        <p>
                                            Born in 1990 in Ishinomaki city, Miyagi prefecture.<br />
                                            Yamagata College Industry &amp; Technology Part-time lecturer.
                                        </p>
                                        <p>
                                            Koh dropped out Tohoku University of Art and Design, Department of Product Design. He worked a design office in Yamagata City, and later a printing company.<br />
                                            He creates various sites including small and medium enterprise sites, government angency related WEB sites / media sites, retail industry EC sites, etc. and is independent in 2019.<br />
                                            incorporated in 2021.
                                        </p>
                                        <p>
                                            Koh's favorite sport is soccer. Favorite music is The Roots. Favorite food is all things tomato-based.
                                        </p>
                                    </div>
                                </div>
                                <div className="about__containerSecBlockTxtTeamBox">
                                    <div className="about__containerSecBlockTxtTeamBoxName">
                                        <div className="about__containerSecBlockTxtTeamBoxNameKanji">
                                            <h3>竹沢 千陽</h3>
                                            <p>デザイナー</p>
                                        </div>
                                        <div className="about__containerSecBlockTxtTeamBoxNameFurigana">
                                            <p>Chiharu Takesawa</p>
                                            <span>Designer</span>
                                        </div>
                                    </div>
                                    <div className="about__containerSecBlockTxtTeamBoxTxt">
                                        <p>2001年宮城県仙台市生まれ。</p>
                                        <p>
                                            東北生活文化大学美術学部美術表現学科を卒業した後に<br></br>
                                            2024年にDESIGN STUDIO Kへ入社。
                                        </p>
                                        <p>
                                            面白いと思ったものは何でも挑戦してみるタイプ。<br></br>
                                            好きな食べ物は青魚と純豆腐。<br></br>
                                            ぬいぐるみが好きで、お気に入りはアボカドと魚のぬいぐるみ。
                                        </p>
                                    </div>
                                    <div className="about__containerSecBlockTxtTeamBoxEn">
                                        <p>Born in 2001 in Sendai City, Miyagi Prefecture.</p>
                                        <p>
                                            After graduating from Tohoku Seikatsu Bunka University, Department of Art,<br></br>
                                            She joined DESIGN STUDIO K in 2024.
                                        </p>
                                        <p>
                                            She is the type of person who tries anything she finds interesting.<br></br>
                                            Her favorite foods are blue fish and pure tofu.<br></br>
                                            She loves stuffed animals, and her favorites are avocado and fish stuffed animals.
                                        </p>
                                    </div>
                                </div>
                                <div className="about__containerSecBlockTxtTeamBox">
                                    <div className="about__containerSecBlockTxtTeamBoxName">
                                        <div className="about__containerSecBlockTxtTeamBoxNameKanji">
                                            <h3>笹原 健太郎</h3>
                                            <p>フロントエンドエンジニア</p>
                                        </div>
                                        <div className="about__containerSecBlockTxtTeamBoxNameFurigana">
                                            <p>Kentarou Sasahara</p>
                                            <span>Front End Engineer</span>
                                        </div>
                                    </div>
                                    <ul className="about__containerSecBlockTxtTeamBoxSns">
                                        <li>
                                            <MagLinks path="https://twitter.com/sasahara_dsk" outer>
                                                <p>X</p>
                                            </MagLinks>
                                        </li>
                                    </ul>
                                    <div className="about__containerSecBlockTxtTeamBoxTxt">
                                        <p>2001年山形県山形市生まれ。</p>
                                        <p>山形県立産業技術短期大学校情報システム科卒業後、2022年DESIGN STUDIO Kに入社。期待のルーキーとして、日々フロントエンド技術を探求する。</p>
                                        <p>好きなゲームはFPSやVR。食への探究心も旺盛。</p>
                                    </div>
                                    <div className="about__containerSecBlockTxtTeamBoxEn">
                                        <p>Born in Yamagata City, Yamagata Prefecture in 2001.</p>
                                        <p>Kentaro joined DESIGN STUDIO K in 2022 after graduating from Yamagata College of Industrial Technology, Department of Information Systems. As a promising rookie, he explores front-end technology on a daily basis.</p>
                                        <p>Kentaro's favorite games are FPS and VR, and he is also very inquisitive about food.</p>
                                    </div>
                                </div>
                                <div className="about__containerSecBlockTxtTeamBox">
                                    <div className="about__containerSecBlockTxtTeamBoxName">
                                        <div className="about__containerSecBlockTxtTeamBoxNameKanji">
                                            <h3>北村 彩</h3>
                                            <p>マネージャー / 経理</p>
                                        </div>
                                        <div className="about__containerSecBlockTxtTeamBoxNameFurigana">
                                            <p>Sayaka Kitamura</p>
                                            <span>Manager / Accounting</span>
                                        </div>
                                    </div>
                                    <div className="about__containerSecBlockTxtTeamBoxTxt">
                                        <p>
                                            1995年山形県米沢市生まれ。
                                        </p>
                                        <p>高校卒業後、地元米沢の老舗旅館や飲食店などでキッチンスタッフとして勤務。若くして次期店長に任命されるなどして小規模店舗マネージメントのノウハウを学ぶ。結婚を期にDESIGN STUDIO Kに入社。</p>
                                        <p>好きな食べ物はラーメン、焼肉、寿司。好きな国は韓国。</p>
                                    </div>
                                    <div className="about__containerSecBlockTxtTeamBoxEn">
                                        <p>Born in Yonezawa City, Yamagata Prefecture in 1995.</p>
                                        <p>Sayaka worked as a kitchen staff at a long-established inn and restaurant in her hometown of Yonezawa after graduating from high school. At a young age, she was appointed as the next manager and learned the know-how of small-scale restaurant management. Joined DESIGN STUDIO K when she got married.</p>
                                        <p>Sayaka's favorite foods are ramen, yakiniku, and sushi. Favorite country is Korea.</p>
                                    </div>
                                </div>
                                <div className="about__containerSecBlockTxtTeamBox">
                                    <div className="about__containerSecBlockTxtTeamBoxName">
                                        <div className="about__containerSecBlockTxtTeamBoxNameKanji">
                                            <h3>酒井 聡</h3>
                                            <p>顧問</p>
                                        </div>
                                        <div className="about__containerSecBlockTxtTeamBoxNameFurigana">
                                            <p>So Sakai</p>
                                            <span>Advisor</span>
                                        </div>
                                    </div>
                                    <ul className="about__containerSecBlockTxtTeamBoxSns">
                                        <li>
                                            <MagLinks path="https://sakaiso.com/" outer>
                                                <p>Web site</p>
                                            </MagLinks>
                                        </li>
                                        <li>
                                            <MagLinks path="https://twitter.com/sakaiso" outer>
                                                <p>X</p>
                                            </MagLinks>
                                        </li>
                                    </ul>
                                    <div className="about__containerSecBlockTxtTeamBoxTxt">
                                        <p>
                                            1979年愛知県岡崎市生まれ。<br></br>
                                            東北芸術工科大学大学院デザイン工学研究科生産デザイン領域 修了、博士（芸術工学）。<br></br>
                                            東北芸術工科大学 デザイン工学部プロダクトデザイン学科　教授／デザイン工学部長・大学院 デザイン工学専攻長、東北大学情報知能システム研究センター 特任教授、SAKAI DESIGN STUDIO　代表、合同会社 logue　ボードメンバー。
                                        </p>
                                        <p>
                                            東北芸術工科大学大学院博士課程にて、「音、振動、光の共調による感性表現の研究」を研究テーマに創作研究活動を行った。その知見から、現在では新しいデジタルサイネージシステムの研究開発や自動車内装部品のインタフェース研究などを手がけている。
                                        </p>
                                    </div>
                                    <div className="about__containerSecBlockTxtTeamBoxEn">
                                        <p>
                                            Born in Okazaki City, Aichi Prefecture in 1979.<br></br>
                                            D. in Art and Design from Tohoku University of Art & Design, Graduate School of Design Engineering.<br></br>
                                            Professor, Department of Product Design, Faculty of Design Engineering, Tohoku University of Art & Design; Dean of Design Engineering, Graduate School of Design Engineering, Tohoku University of Art & Design; Specially Appointed Professor, Intelligent Information System research center, Tohoku University; Representative, SAKAI DESIGN STUDIO; Board Member, logue, LLC.
                                        </p>
                                        <p>
                                            In his doctoral program at Tohoku University of Art & Design, he conducted creative research activities under the research theme of "research on the expression of sensitivity through the symbiosis of sound, vibration, and light. Based on his knowledge, he is currently involved in the research and development of new digital signage systems and interface research for automobile interior parts.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about__containerSec">
                    <div className="about__containerSecBlock">
                        <div className="about__containerSecBlockTtl">
                            <h2>Profile</h2>
                        </div>
                        <div className="about__containerSecBlockTxt">
                            <div className="about__containerSecBlockTxtTable">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>会社名</th>
                                            <td>合同会社 DESIGN STUDIO K</td>
                                        </tr>
                                        <tr>
                                            <th>所在地</th>
                                            <td>
                                                〒990-2462<br />
                                                山形県山形市深町2-2-22
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>TEL</th>
                                            <td>023-608-1178</td>
                                        </tr>
                                        <tr>
                                            <th>設立</th>
                                            <td>2021年4月</td>
                                        </tr>
                                        <tr>
                                            <th>取引銀行</th>
                                            <td>
                                                日本政策金融公庫<br />
                                                山形信用金庫<br />
                                                みずほ銀行<br />
                                                山形銀行
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>代表者</th>
                                            <td>代表社員　北村 洸</td>
                                        </tr>
                                        <tr>
                                            <th>資本金</th>
                                            <td>¥500,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default aboutPage
